var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.infoBox},[_c('div',{class:_vm.$style.info},[_c('span',{class:_vm.$style.iSub},[_vm._v("拍摄: "),_c('span',{class:_vm.$style.iLabel},[_vm._v(_vm._s(_vm.fileDetail.userName))])]),_c('span',{class:_vm.$style.iSub},[_vm._v("创建时间："),_c('span',{class:_vm.$style.iLabel},[_vm._v(_vm._s(_vm.dateFormat(_vm.fileDetail.createTime)))])]),_c('span',{class:_vm.$style.iSub},[_vm._v("位置："),_c('span',{class:_vm.$style.iActive,on:{"click":function($event){return _vm.handleMap(_vm.fileDetail)}}},[_vm._v(_vm._s(_vm.fileDetail.address))])])]),_c('div',{class:_vm.$style.actionOperation},[_c('span',{class:_vm.$style.iconOperation},[_c('x-icon',{class:_vm.$style.iconBtn,attrs:{"type":"tc-icon-layout-v"},on:{"click":function($event){return _vm.handleShowPhotoList(!_vm.showPhoto)}}}),_c('a-icon',{class:_vm.$style.iconBtn,attrs:{"type":"edit"},on:{"click":_vm.notesEdit}})],1),(_vm.showContext)?_c('span',_vm._l((_vm.storeButtons),function(item){return _c('a-button',{key:item.label,class:_vm.$style.btn,attrs:{"type":item.type},on:{"click":function($event){return _vm.toolbarClick('action', item)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e()])]),(_vm.showPhoto)?_c('div',{class:_vm.$style.pictureWrapper},[_c('span',{class:_vm.$style.pictureLabel,on:{"click":function($event){$event.stopPropagation();return _vm.getPhotoPreData.apply(null, arguments)}}},[_c('a-icon',{attrs:{"type":"left"}})],1),_vm._l((_vm.photoList),function(item){return _c('x-oss-image',{key:item.fileId,class:[
        _vm.$style.picture,
        _vm.$route.params.id === item.fileId ? _vm.$style.active : '' ],attrs:{"basePath":"/oss/iot/oss","ossPath":_vm.relativePath(item.path)},on:{"click":function($event){return _vm.changePicture(item.fileId)}}})}),_c('span',{class:_vm.$style.pictureLabel,on:{"click":function($event){$event.stopPropagation();return _vm.getPhotoNextData.apply(null, arguments)}}},[_c('a-icon',{attrs:{"type":"right"}})],1)],2):_vm._e(),_c('div',{class:_vm.$style.contentWrapper},[_c('img',{class:[_vm.$style.image],attrs:{"crossorigin":"anonymous","id":"sampleImage","src":_vm.imageSrc},on:{"load":_vm.showMarkers,"click":_vm.showMarkers}}),(_vm.showContext)?_c('div',{class:_vm.$style.operationWrapper},[_c('div',{class:_vm.$style.actionButtonWrapper},_vm._l((_vm.actionButtons),function(item){return _c('div',{key:item.title,class:[
            _vm.$style.actionButton,
            _vm.actionActived === item.label ? _vm.$style.actived : '' ],domProps:{"innerHTML":_vm._s(item.icon)},on:{"click":function($event){return _vm.toolbarClick('action', item)}}})}),0),_c('div',{ref:"markerButtonBox",class:_vm.$style.markerButtonWrapper},_vm._l((_vm.markerButtons),function(item){return _c('div',{key:item.title,class:[
            _vm.$style.markerButton,
            _vm.actionActived === item.label ? _vm.$style.actived : '' ],domProps:{"innerHTML":_vm._s(item.icon)},on:{"pointerover":function($event){return _vm.markerClick(item)}}})}),0)]):_vm._e(),(_vm.showContext)?_c('div',{class:[_vm.$style.dialogWrapper, _vm.menuShow ? '' : _vm.$style.menuShow]},[_c('div',{class:_vm.$style.dialogTitle},[(!_vm.menuShow)?_c('span',{class:_vm.$style.mIconWrap},[_c('a-icon',{class:_vm.$style.mIcon,attrs:{"type":"menu-fold"},on:{"click":_vm.handleMenu}})],1):_c('span',{class:_vm.$style.titleWrap},[_c('span',[_vm._v("图层")]),_c('a-icon',{class:_vm.$style.hIcon,attrs:{"type":"menu-unfold"},on:{"click":_vm.handleMenu}})],1)]),_c('div',{class:_vm.$style.dialogContextWrapper},_vm._l((_vm.markers),function(item){return _c('div',{key:item.uuid,class:[
            _vm.$style.dialogContext,
            _vm.activedMarker === item ? _vm.$style.actived : '' ]},[_c('span',{class:_vm.$style.colorSet,style:({ backgroundColor: item.strokeColor })}),_c('span',{class:_vm.$style.svgIconSet,domProps:{"innerHTML":_vm._s(_vm.getIcon(item.typeName))}}),_c('span',{class:_vm.$style.marginSet},[_c('a-select',{staticStyle:{"width":"114px"},attrs:{"disabled":""},model:{value:(item.idxTagId),callback:function ($$v) {_vm.$set(item, "idxTagId", $$v)},expression:"item.idxTagId"}},_vm._l((_vm.subList(item.tagGroupID)),function(subTag){return _c('a-select-option',{key:subTag.pkId,attrs:{"value":subTag.pkId}},[_vm._v(" "+_vm._s(subTag.tagName)+" ")])}),1)],1),_c('span',{class:_vm.$style.marginSet},[(item.opacity === 1)?_c('a-icon',{attrs:{"type":"eye"},on:{"click":function($event){$event.stopPropagation();return _vm.showOrHideMarker(item, 0)}}}):_c('a-icon',{attrs:{"type":"eye-invisible"},on:{"click":function($event){$event.stopPropagation();return _vm.showOrHideMarker(item, 1)}}})],1),_c('span',{class:_vm.$style.marginSet},[_c('a-icon',{attrs:{"type":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.editMarker(item)}}})],1),_c('span',{class:_vm.$style.marginSet},[_c('x-icon',{attrs:{"type":"tc-icon-copy"},on:{"click":function($event){$event.stopPropagation();return _vm.copyMarker(item)}}})],1),_c('span',{class:_vm.$style.marginSet},[_c('x-icon',{attrs:{"type":"tc-icon-delete"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteMarker(item)}}})],1)])}),0)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showToolbox),expression:"showToolbox"},{name:"drag",rawName:"v-drag"}],class:_vm.$style.styleBoxWrapper,attrs:{"id":"styleBox"}}),_c('div',{class:_vm.$style.contentBox,style:({ zIndex: _vm.showContext ? 98 : -1 }),attrs:{"id":"contentBox"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }