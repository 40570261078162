<template>
  <div :class="$style.wrapper">
    <div :class="$style.infoBox">
      <div :class="$style.info">
        <span :class="$style.iSub"
          >拍摄:
          <span :class="$style.iLabel">{{ fileDetail.userName }}</span></span
        >
        <span :class="$style.iSub"
          >创建时间：<span :class="$style.iLabel">{{
            dateFormat(fileDetail.createTime)
          }}</span></span
        >
        <span :class="$style.iSub"
          >位置：<span :class="$style.iActive" @click="handleMap(fileDetail)">{{
            fileDetail.address
          }}</span></span
        >
      </div>
      <div :class="$style.actionOperation">
        <span :class="$style.iconOperation">
          <x-icon
            :class="$style.iconBtn"
            type="tc-icon-layout-v"
            @click="handleShowPhotoList(!showPhoto)"
          />
          <a-icon :class="$style.iconBtn" type="edit" @click="notesEdit" />
          <!-- <a-icon :class="$style.iconBtn" type="fullscreen" /> -->
        </span>
        <span v-if="showContext"
          ><a-button
            v-for="item in storeButtons"
            :type="item.type"
            :key="item.label"
            :class="$style.btn"
            @click="toolbarClick('action', item)"
            >{{ item.title }}</a-button
          >
        </span>
      </div>
    </div>
    <div :class="$style.pictureWrapper" v-if="showPhoto">
      <span :class="$style.pictureLabel" @click.stop="getPhotoPreData">
        <a-icon type="left" />
      </span>
      <x-oss-image
        :class="[
          $style.picture,
          $route.params.id === item.fileId ? $style.active : '',
        ]"
        @click="changePicture(item.fileId)"
        basePath="/oss/iot/oss"
        :ossPath="relativePath(item.path)"
        v-for="item in photoList"
        :key="item.fileId"
      />
      <span :class="$style.pictureLabel" @click.stop="getPhotoNextData">
        <a-icon type="right" />
      </span>
    </div>
    <div :class="$style.contentWrapper">
      <img
        crossorigin="anonymous"
        :class="[$style.image]"
        id="sampleImage"
        :src="imageSrc"
        @load="showMarkers"
        @click="showMarkers"
      />
      <div v-if="showContext" :class="$style.operationWrapper">
        <div :class="$style.actionButtonWrapper">
          <div
            :class="[
              $style.actionButton,
              actionActived === item.label ? $style.actived : '',
            ]"
            v-for="item in actionButtons"
            :key="item.title"
            v-html="item.icon"
            @click="toolbarClick('action', item)"
          ></div>
        </div>
        <div :class="$style.markerButtonWrapper" ref="markerButtonBox">
          <div
            :class="[
              $style.markerButton,
              actionActived === item.label ? $style.actived : '',
            ]"
            v-for="item in markerButtons"
            :key="item.title"
            v-html="item.icon"
            @pointerover="markerClick(item)"
          ></div>
          <!-- @click="markerClick(item)" -->
        </div>
      </div>
      <div
        v-if="showContext"
        :class="[$style.dialogWrapper, menuShow ? '' : $style.menuShow]"
      >
        <div :class="$style.dialogTitle">
          <span :class="$style.mIconWrap" v-if="!menuShow">
            <a-icon
              :class="$style.mIcon"
              @click="handleMenu"
              type="menu-fold"
            />
          </span>
          <span :class="$style.titleWrap" v-else>
            <span>图层</span>
            <a-icon
              :class="$style.hIcon"
              @click="handleMenu"
              type="menu-unfold"
            />
          </span>
        </div>
        <div :class="$style.dialogContextWrapper">
          <div
            :class="[
              $style.dialogContext,
              activedMarker === item ? $style.actived : '',
            ]"
            v-for="item in markers"
            :key="item.uuid"
          >
            <span
              :class="$style.colorSet"
              :style="{ backgroundColor: item.strokeColor }"
            ></span>
            <!-- <a-checkbox></a-checkbox> -->
            <span
              :class="$style.svgIconSet"
              v-html="getIcon(item.typeName)"
            ></span>
            <span :class="$style.marginSet">
              <a-select v-model="item.idxTagId" disabled style="width: 114px">
                <a-select-option
                  :value="subTag.pkId"
                  :key="subTag.pkId"
                  v-for="subTag in subList(item.tagGroupID)"
                >
                  {{ subTag.tagName }}
                </a-select-option>
              </a-select>
            </span>
            <span :class="$style.marginSet">
              <a-icon
                v-if="item.opacity === 1"
                type="eye"
                @click.stop="showOrHideMarker(item, 0)"
              />
              <a-icon
                v-else
                type="eye-invisible"
                @click.stop="showOrHideMarker(item, 1)"
              />
            </span>
            <span :class="$style.marginSet">
              <a-icon type="edit" @click.stop="editMarker(item)" />
            </span>
            <span :class="$style.marginSet">
              <x-icon type="tc-icon-copy" @click.stop="copyMarker(item)" />
            </span>
            <span :class="$style.marginSet">
              <x-icon type="tc-icon-delete" @click.stop="deleteMarker(item)" />
            </span>
          </div>
        </div>
      </div>
      <div
        v-show="showToolbox"
        v-drag
        :class="$style.styleBoxWrapper"
        id="styleBox"
      ></div>
      <div
        :style="{ zIndex: showContext ? 98 : -1 }"
        :class="$style.contentBox"
        id="contentBox"
      ></div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  MarkerArea,
  CalloutMarker,
  EllipseMarker,
  PolylineMarker,
  PolygonMarker,
} from './marker';
import SelectIcon from '!!svg-inline-loader!./marker/ui/toolbar-core-icons/cursor.svg';
import FitIcon from '!!svg-inline-loader!./marker/ui/toolbar-core-icons/fit.svg';
import ZoomIcon from '!!svg-inline-loader!./marker/ui/toolbar-core-icons/zoom.svg';
import { Toolbox } from './marker/ui/Toolbox';
// eslint-disable-next-line no-unused-vars
import drag from './drag';
import { createFormModal, createModal } from '@triascloud/x-components';
import AddMarkerDialog from './add-marker-dialog.vue';
import { putRemark } from '@/services/smart-hat/file';
import MarkEdit from '../mark-edit.vue';
import { cloneDeep } from 'lodash';
import EditNotes from './edit-notes.vue';
import { getTagGroup } from '@/services/smart-hat/tag-group';
import { getFileDetail } from '@/services/smart-hat/file';
import { UploadService } from '@triascloud/services';
import dayjs from 'dayjs';
import { saveFileMark, getFileMark } from '@/services/smart-hat/file-mark';
import { HabitService } from '@triascloud/services';
import { promiseLock } from '@triascloud/utils';
import { defaultColors } from '@/components/custom-color-picker';
import { fileTable } from '@/services/smart-hat/file';
import FileMap from '../file-map.vue';

/** oss图片缩放 */
const relativePath = abPath => {
  const result = abPath.match(/com\/(\S*)\?/)[1];
  return result + '?x-oss-process=image/resize,h_160,m_lfit';
};

const ACTION_KEY = {
  Select: 'select',
  Delete: 'delete',
  ZoomOut: 'zoom-out',
  Zoom: 'zoom',
  Render: 'render',
  Close: 'close',
  CalloutMarker: CalloutMarker.typeName,
  PolylineMarker: PolylineMarker.typeName,
  EllipseMarker: EllipseMarker.typeName,
  PolygonMarker: PolygonMarker.typeName,
};

/** action为临时状态，立马切换为最终态 select */
const ACTION_SELECT_KEY_LIST = [ACTION_KEY.Zoom, ACTION_KEY.ZoomOut];

@Component({})
export default class Marking extends Vue {
  dateFormat(time) {
    if (time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    }
    return time;
  }

  relativePath(path) {
    if (path) {
      return relativePath(path);
    } else {
      return path;
    }
  }

  menuShow = false;
  handleMenu() {
    this.menuShow = !this.menuShow;
  }

  /** @type {import('./marker/MarkerArea').MarkerArea} */
  MarkerArea;
  created() {
    this.getColorList('form.design.color.picker');
  }

  async mounted() {
    await Promise.all([this.getData(), this.getFileData(), this.getMarkers()]);
    this.getPhotoData();
  }

  handleMap(record) {
    if (record.lng && record.lat) {
      const center = {
        lng: +record.lng,
        lat: +record.lat,
      };
      createModal(
        () => (
          <FileMap
            center={center}
            record={{
              deviceId: record.idxDeviceId,
            }}
          />
        ),
        {
          title: '地理位置',
          width: 700,
        },
      );
    }
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }
  @promiseLock({ keyGenerator: identifier => identifier })
  async getColorList(identifier) {
    this.customColorList = ((await this.habitService.getItem(identifier)) || '')
      .split(',')
      .filter(item => item);

    if (this.customColorList.length > 7) {
      this.customColorList = this.customColorList.slice(0, 7);
    }
    if (this.MarkerArea) {
      this.MarkerArea.settings.defaultColorSet = this.colorList;
    }
  }
  colors = [...defaultColors];
  get colorList() {
    return [...this.colors, ...this.customColorList, '#ffffff'];
  }

  photoList = [];
  photoCurrent = 1;
  photoPage = 0;
  async getPhotoData() {
    const { records, pages } = await fileTable({
      type: 'PHOTO',
      size: 8,
      current: this.photoCurrent,
    });
    this.photoPage = pages;
    this.photoList = records;
  }
  getPhotoPreData() {
    if (this.photoCurrent > 1) {
      this.photoCurrent = this.photoCurrent - 1;
      this.getPhotoData();
    }
  }
  getPhotoNextData() {
    if (this.photoCurrent < this.photoPage) {
      this.photoCurrent = this.photoCurrent + 1;
      this.getPhotoData();
    }
  }
  async changePicture(fileId) {
    this.handleShowPhotoList(false);
    await this.$router.push(`/hat/file/marking/${fileId}`);
    this.markersRemove();
    await Promise.all([this.getData(), this.getFileData(), this.getMarkers()]);
    this.getPhotoData();
  }
  markersRemove() {
    this.markers = [];
    this.MarkerArea.clear();
    this.defaultState = {};
    if (this.MarkerArea.toolbox.uiContainer !== undefined) {
      this.MarkerArea.toolbox.uiContainer.innerHTML = '';
      const styleBox = document.getElementById('styleBox');
      styleBox.innerHTML = '';
    }
    this.showToolbox = false;
  }

  defaultState = {};
  async getMarkers() {
    const data = await getFileMark(this.$route.params.id);
    if (data.length > 0) {
      const item = data[0];
      const state = {
        width: item.imgWidth,
        height: item.imgHeight,
        markers: data,
      };
      this.defaultState = state;
    }
  }

  async saveMarkers(data) {
    try {
      await saveFileMark(data);
      this.$message.success('保存成功！');
      this.back();
    } catch (error) {
      return;
    }
  }
  back() {
    this.$router.push('/hat/file');
  }

  imageSrc = '';
  fileDetail = {};
  async getFileData() {
    const data = await getFileDetail(this.$route.params.id);
    this.fileDetail = data;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(data.path);
    this.imageSrc = abPath;
  }

  tagList = [];
  totalSubTagList = [];
  async getData() {
    const data = await getTagGroup();
    this.tagList = data;
    data.forEach(item => {
      this.totalSubTagList.push(...item.tags);
    });
  }
  subList(groupId) {
    if (groupId) {
      const item = this.tagList.find(v => v.pkId === groupId);
      return item.tags;
    }
    return [];
  }

  getIcon(typeName) {
    switch (typeName) {
      case ACTION_KEY.CalloutMarker:
        return CalloutMarker.icon;
      case ACTION_KEY.PolylineMarker:
        return PolylineMarker.icon;
      case ACTION_KEY.EllipseMarker:
        return EllipseMarker.icon;
      case ACTION_KEY.PolygonMarker:
        return PolygonMarker.icon;
      default:
        return '';
    }
  }

  actionActived = ACTION_KEY.Select;

  actionButtons = [
    {
      icon: SelectIcon,
      value: 'select',
      title: '光标，可移动标记',
      label: ACTION_KEY.Select,
    },
    {
      icon: FitIcon,
      value: 'zoom-out',
      title: '将图片自适应屏幕展示',
      label: ACTION_KEY.ZoomOut,
    },
    {
      icon: ZoomIcon,
      value: 'zoom',
      title: '放大图片',
      label: ACTION_KEY.Zoom,
    },
  ];
  storeButtons = [
    {
      title: '取消',
      type: '',
      value: 'close',
      label: ACTION_KEY.Close,
    },
    {
      title: '保存',
      type: 'primary',
      value: 'render',
      label: ACTION_KEY.Render,
    },
  ];

  markerButtons = [
    {
      icon: CalloutMarker.icon,
      value: CalloutMarker,
      title: '添加标签',
      label: ACTION_KEY.CalloutMarker,
      desc: '单击添加标签',
    },
    {
      icon: PolylineMarker.icon,
      value: PolylineMarker,
      title: '添加线段',
      label: ACTION_KEY.PolylineMarker,
      desc: '单击添加点（至少2个），双击最后一个点结束绘制',
    },
    {
      icon: EllipseMarker.icon,
      value: EllipseMarker,
      title: '添加椭圆',
      label: ACTION_KEY.EllipseMarker,
      desc: '单击拖拽绘制，停止单击结束绘制',
    },
    {
      icon: PolygonMarker.icon,
      value: PolygonMarker,
      title: '添加多边形',
      label: ACTION_KEY.PolygonMarker,
      desc: '单击添加顶点，首尾顶点闭合绘制完成',
    },
  ];

  markers = [];
  showContext = false;
  showToolbox = false;
  activedMarker = {};
  showMarkers() {
    const bodyCss = getComputedStyle(document.body);
    this.showContext = !this.showContext;
    const target = document.getElementById('sampleImage');
    const targetRoot = document.getElementById('contentBox');
    this.MarkerArea = new MarkerArea(target, targetRoot);
    this.MarkerArea.uiStyleSettings.zoomButtonVisible = true;
    this.MarkerArea.uiStyleSettings.zoomOutButtonVisible = true;
    this.MarkerArea.uiStyleSettings.canvasBackgroundColor = bodyCss.getPropertyValue(
      '--body-bg',
    );
    this.MarkerArea.uiStyleSettings.toolboxColor = bodyCss.getPropertyValue(
      '--font',
    );
    this.MarkerArea.uiStyleSettings.toolboxAccentColor = bodyCss.getPropertyValue(
      '--font-active',
    );

    this.markerSave();
    this.markerRestoreState();
    this.markerShow();
    this.markerUI();
    this.markerSelectState();
    this.markerClose();
    this.markerCreated();

    this.MarkerArea.show();

    if (this.colorList.length > 0) {
      this.MarkerArea.settings.defaultColorSet = this.colorList;
    }

    if (this.defaultState && this.defaultState.markers) {
      this.restoreState(this.defaultState);
    }
  }

  /** @name 标记工具按钮UI */
  markerUI() {
    const _this = this;
    /**
     * toolbar func
     * 1、setActiveMarkerButton
     * 2、setSelectMode
     * 3、setCurrentMarker 入参可为空
     * 4、adjustLayout
     */
    function Toolbar() {
      this.setActiveMarkerButton = function(typeName) {
        _this.actionActived = typeName;
      };
      this.setSelectMode = function() {
        _this.actionActived = ACTION_KEY.Select;
      };
      this.setCurrentMarker = function() {
        // console.log(marker);
      };
      this.adjustLayout = function() {};
    }
    this.MarkerArea.addEventListener('showui', () => {
      this.MarkerArea.toolbar = new Toolbar();

      const styleBox = document.getElementById('styleBox');
      const toolbox = new Toolbox(
        styleBox,
        this.MarkerArea.settings.displayMode,
        this.MarkerArea.uiStyleSettings,
        this.MarkerArea.styles,
      );
      this.MarkerArea.toolbox = toolbox;
      // 显示底部按钮面板
      toolbox.show(
        this.MarkerArea._silentRenderMode ||
          this.MarkerArea.uiStyleSettings.hideToolbox
          ? 'hidden'
          : 'visible',
      );
    });
  }

  /** @name 标记选择状态 */
  markerSelectState() {
    this.MarkerArea.addEventListener('markerselect', () => {
      this.showToolbox = true;
      this.activedMarker = this.MarkerArea._currentMarker;
    });
    this.MarkerArea.addEventListener('markerdeselect', () => {
      this.showToolbox = false;
      this.activedMarker = {};
    });
  }

  /** @name 标记关闭 */
  markerClose() {
    this.MarkerArea.addEventListener('close', () => {
      this.showContext = false;
      this.markers = [];
      this.back();
    });
  }

  /** @name 标记创建完成后 */
  markerCreated() {
    this.MarkerArea.addEventListener('markercreate', event => {
      const list = event.markerArea.markers;
      if (this.markerForm) {
        const newMarker = list[list.length - 1];
        newMarker.tagGroupID = this.markerForm.groupId;
        newMarker.idxTagId = this.markerForm.subTagId;
        newMarker.probability = this.markerForm.probability;
        newMarker.sort = list.length;

        // 处理标记TAG(CalloutMarker)
        if (newMarker.typeName === CalloutMarker.typeName) {
          const tagItem = this.findTagItemByTagId(newMarker.idxTagId);
          if (tagItem) {
            newMarker.text = tagItem.tagName;
          }
        }
      }
      this.markers = list;
      this.actionActived = ACTION_KEY.Select;
    });
  }

  findTagItemByTagId(tagId) {
    return this.totalSubTagList.find(v => v.pkId === tagId);
  }

  /** @name 标记保存回调 */
  markerSave() {
    const target = document.getElementById('sampleImage');
    this.MarkerArea.addEventListener('render', event => {
      target.src = event.dataUrl;
      // console.log(event.state);
      this.saveMarkers({
        ...event.state,
        fileId: this.$route.params.id,
      });
    });
  }

  /** @name 标记加载数据回调 */
  markerRestoreState() {
    this.MarkerArea.addEventListener('restorestate', event => {
      this.markers = event.markerArea.markers;
    });
  }

  /** @name 标记显示回调 */
  markerShow() {
    this.MarkerArea.addEventListener('show', () => {
      this.showContext = true;
    });
  }

  restoreState(state) {
    if (state && state.markers.length > 0) {
      state.markers.forEach(marker => {
        const item = this.totalSubTagList.find(
          tag => tag.pkId === marker.idxTagId,
        );
        if (item) {
          marker['tagGroupID'] = item.parentId;
        }
        // 处理标记TAG(CalloutMarker)
        if (marker.typeName === CalloutMarker.typeName) {
          const tagItem = this.findTagItemByTagId(marker.idxTagId);
          if (tagItem) {
            marker.attributes.text = tagItem.tagName;
          }
        }
      });

      this.MarkerArea.restoreState(state);
    }
  }

  toolbarClick(buttonType, item, opts = {}) {
    this.actionActived = item.label;
    if (ACTION_SELECT_KEY_LIST.some(v => v === item.label)) {
      this.actionActived = ACTION_KEY.Select;
    }

    this.MarkerArea.toolbarButtonClicked(buttonType, item.value, opts);
  }

  copyMarker(item) {
    let value;
    switch (item.typeName) {
      case CalloutMarker.typeName:
        value = CalloutMarker;
        break;
      case PolylineMarker.typeName:
        value = PolylineMarker;
        break;
      case EllipseMarker.typeName:
        value = EllipseMarker;
        break;
      case PolygonMarker.typeName:
        value = PolygonMarker;
        break;
    }
    const markerState = cloneDeep(item.getState());
    markerState.sort = markerState.sort + 1;
    markerState.pkId = '';
    this.MarkerArea.copyNewMarker(value, markerState);
  }

  async editMarker(item) {
    const form = await createFormModal(() => <EditNotes item={item} />, {
      title: '标签编辑',
      width: 440,
    });
    if (form) {
      item.remark = form.remark;
      item.probability = form.probability;
      item.tagGroupID = form.groupId;
      item.idxTagId = form.subTagId;
      if (item.typeName === CalloutMarker.typeName) {
        const tagItem = this.findTagItemByTagId(item.idxTagId);
        if (tagItem) {
          item.text = tagItem.tagName;
        }
        const fItem = this.MarkerArea.markers.find(v => v.uuid === item.uuid);
        if (fItem) {
          fItem.text = tagItem.tagName;
        }
        item.renderText();
      }
    }
  }

  deleteMarker(item) {
    this.MarkerArea.deleteMarker(item);
  }

  showOrHideMarker(item, value) {
    item.setOpacity(value);
  }

  markerForm = null;
  markerDialog = null;
  markerDialogClose() {
    this.markerDialog && this.markerDialog.handleClose();
  }
  markerDialogSave(form, item) {
    if (form && form.groupId) {
      this.markerForm = form;
      const opts = {
        defaultColor: form.tagColor,
      };
      if (item.value === CalloutMarker) {
        const tagItem = this.findTagItemByTagId(form.subTagId);
        if (tagItem) {
          opts['text'] = tagItem.tagName;
        }
      }
      this.toolbarClick('marker', item, opts);
    }
  }
  async markerClick(item) {
    this.markerDialogClose();
    this.markerForm = null;
    this.actionActived = item.label;
    const markerDialog = createModal(
      () => (
        <AddMarkerDialog
          close={this.markerDialogClose}
          save={form => this.markerDialogSave(form, item)}
        />
      ),
      {
        width: 350,
        getContainer: () => this.$refs.markerButtonBox,
        closable: false,
        title: (
          <div class={this.$style.dialogTitleWrap}>
            <span class={this.$style.mainTitle}>{item.title}</span>
            <span class={this.$style.subTitle}>{item.desc}</span>
          </div>
        ),
      },
    );
    this.markerDialog = markerDialog;
  }

  showPhoto = false;
  handleShowPhotoList(flag) {
    this.showPhoto = flag;
  }

  async notesEdit() {
    try {
      const record = {
        mark: this.fileDetail.mark,
        fileId: this.$route.params.id,
      };
      const result = await createFormModal(
        () => <MarkEdit mark={record.mark} />,
        {
          width: 400,
          title: '文件备注',
        },
      );
      if (result) {
        await putRemark({
          mark: result,
          fileId: record.fileId,
        });
      }
    } catch (error) {
      return false;
    }
  }
}
</script>
<style lang="less" module>
@width: 276px;
.wrapper {
  overflow: hidden;
  position: relative;
  .contentWrapper {
    position: relative;
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
  }
  .infoBox {
    width: 100%;
    padding: 0 10px;
    height: 56px;
    background-color: var(--scroll-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-sub);
    font-size: 14px;
    .info {
      .iSub {
        color: var(--font-info);
        margin-right: 30px;
      }
      .iActive {
        color: var(--font-active);
        text-decoration: underline;
        cursor: pointer;
      }
      .iLabel {
        color: var(--font);
      }
    }
    .actionOperation {
      .btn + .btn {
        margin-left: 10px;
      }
    }
    .iconOperation {
      margin-right: 40px;
      .iconBtn {
        color: var(--font);
        font-size: 16px;
      }
      .iconBtn + .iconBtn {
        margin-left: 22px;
      }
    }
  }

  .operationWrapper {
    position: absolute;
    z-index: 99;
    top: 12px;
    left: 12px;
  }
  .actionButtonWrapper {
    background-color: var(--main-bg);
    border-radius: 4px;
    .actionButton {
      box-sizing: content-box;
      width: 20px;
      height: 20px;
      padding: 8px;
      fill: var(--font);
      &:hover {
        fill: var(--font-active);
        background-color: var(--primary-20);
      }
      &.actived {
        fill: var(--font-active);
        background-color: var(--primary-20);
      }
    }
    .actionButton + .actionButton {
      border-top: 1px solid var(--border);
    }
  }
  .markerButtonWrapper {
    margin-top: 10px;
    :global {
      .ant-modal-mask {
        background-color: transparent;
      }
      .ant-modal {
        position: absolute;
        top: 168px;
        left: 68px;
      }
    }
    .markerButton {
      box-sizing: content-box;
      width: 20px;
      height: 20px;
      padding: 8px;
      border-radius: 4px;
      background-color: var(--main-bg);
      fill: var(--font);
      &:hover {
        fill: var(--font-active);
        background-color: var(--primary-20);
      }
      &.actived {
        fill: var(--font-active);
        background-color: var(--primary-20);
      }
    }
    .markerButton + .markerButton {
      margin-top: 10px;
    }
  }

  .pictureWrapper {
    height: 181px;
    display: flex;
    align-items: center;
    width: calc(100% - 24px);
    box-sizing: content-box;
    margin: 0 12px;
    position: absolute;
    top: 68px;
    background-color: var(--main-bg);
    border-radius: 12px;
    z-index: 100;
    .pictureLabel {
      width: 51px;
      display: inline-flex;
      justify-content: center;
      font-size: 24px;
      cursor: pointer;
    }
    .picture {
      width: 208px;
      height: 160px;
      border-radius: 12px;
      box-sizing: content-box;
      &.active {
        outline: 2px solid var(--primary);
      }
    }
    .picture + .picture {
      margin-left: 18px;
    }
  }

  .contentBox {
    position: absolute;
    display: flex;
    width: 100%;
    height: calc(100vh - 56px);
    background-color: var(--body-bg);
    z-index: -1;
  }
  .dialogWrapper {
    position: absolute;
    top: 12px;
    right: 12px;
    width: @width;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    z-index: 99;
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
    :global {
      .ant-checkbox + span {
        padding-left: 8px;
      }
    }
    &.menuShow {
      transform: translateX(0);
      height: 32px !important;
      width: 32px;
      .dialogContextWrapper {
        height: 0;
      }
    }
    .titleWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 10px;
    }
    .mIconWrap {
      width: 14px;
      height: 14px;
      display: inline-flex;
      .mIcon {
        font-size: 16px;
        color: var(--font);
        cursor: pointer;
      }
    }
    .hIcon {
      font-size: 16px;
      color: var(--font);
      cursor: pointer;
    }
    .dialogTitle {
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      color: var(--font);
      border-bottom: 1px solid var(--border);
      padding-left: 10px;
      &.actived {
        color: var(--font-active);
      }
    }
    .dialogContextWrapper {
      height: 496px;
      overflow-y: auto;
      overflow-x: hidden;
      :global {
        .ant-checkbox-wrapper {
          display: inline-flex;
          align-items: center;
        }
      }
      .dialogContext {
        height: 56px;
        line-height: 56px;
        padding: 0 10px;
        position: relative;
        border-bottom: 1px solid var(--border-fade-15);
        display: flex;
        align-items: center;
        &.actived {
          background-color: var(--primary-fade-10);
        }
        .svgIconSet {
          width: 16px;
          height: 16px;
          fill: var(--font);
          display: inline-flex;
          margin-left: 6px;
        }
        .marginSet {
          margin-left: 10px;
        }
        .colorSet {
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 56px;
          background-color: #fcc614;
        }
      }
    }
  }
  .styleBoxWrapper {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: @width;
    height: 100px;
    // background-color: var(--main-bg);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    z-index: 99;
  }
  .dialogTitleWrap {
    display: flex;
    flex-direction: column;
  }
  .mainTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--font);
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    color: var(--font-info);
  }
}
</style>
