<template>
  <a-form-model
    :model="form"
    ref="form"
    hideRequiredMark
    :class="$style.form"
    validateOnRuleChange
    :rules="rules"
  >
    <a-form-model-item labelAlign="left" label="选择标签">
      <a-select
        style="width: 134px"
        v-model="form.groupId"
        @change="changeGroup"
      >
        <a-select-option
          :key="item.pkId"
          :value="item.pkId"
          v-for="item in panes"
        >
          {{ item.tagName }}
        </a-select-option>
      </a-select>
      <a-select style="width: 134px" v-model="form.subTagId">
        <a-select-option
          :key="item.pkId"
          :value="item.pkId"
          v-for="item in subList"
        >
          {{ item.tagName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item labelAlign="left" prop="probability" label="可信度">
      <div :class="$style.reliability">
        <a-slider v-model="form.probability" :class="$style.sliderBox" /><span
          :class="$style.info"
          >{{ form.probability }}</span
        >
      </div>
    </a-form-model-item>
    <a-form-model-item label="备注内容" prop="remark">
      <a-input
        v-model="form.remark"
        placeholder="请输入备注内容，限100字符长度"
        :maxLength="100"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getTagGroup } from '@/services/smart-hat/tag-group';
@Component
export default class EditNotes extends Vue {
  @Prop({ type: Object }) item;

  mounted() {
    this.getData();

    this.form.probability = Number((this.item.probability * 100).toFixed(0));
    this.form.remark = this.item.remark;
    this.form.groupId = this.item.tagGroupID;
    this.form.subTagId = this.item.idxTagId;
  }

  get subList() {
    if (this.form.groupId) {
      const item = this.panes.find(v => v.pkId === this.form.groupId);
      return item.tags;
    }
    return [];
  }

  panes = [];
  async getData() {
    const data = await getTagGroup();
    this.panes = data;
  }

  changeGroup() {
    this.form.subTagId = '';
  }

  form = {
    probability: 100,
    remark: '',
    groupId: '',
    subTagId: '',
  };
  rules = {};

  async getValue() {
    await this.$refs.form.validate();
    const item = JSON.parse(JSON.stringify(this.form));
    item.probability = Number((this.form.probability / 100).toFixed(2));
    return item;
  }
}
</script>
<style lang="less" module>
.form {
  .rightIcon {
    margin-left: 10px;
  }
  .reliability {
    display: flex;
    align-items: center;
    :global {
      .ant-slider {
        margin: 14px 6px;
      }
    }
    .sliderBox {
      flex: 1;
    }
    .info {
      flex-basis: 30px;
      text-align: right;
    }
  }
}
</style>
