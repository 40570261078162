<template>
  <a-form-model
    :model="form"
    ref="form"
    hideRequiredMark
    :class="$style.form"
    validateOnRuleChange
    :rules="rules"
  >
    <a-form-model-item labelAlign="left" label="选择标签" prop="subTagId">
      <a-select
        style="width: 134px"
        v-model="form.groupId"
        @change="changeGroup"
      >
        <a-select-option
          :key="item.pkId"
          :value="item.pkId"
          v-for="item in panes"
        >
          {{ item.tagName }}
        </a-select-option>
      </a-select>
      <a-select style="width: 134px" v-model="form.subTagId">
        <a-select-option
          :key="item.pkId"
          :value="item.pkId"
          v-for="item in subList"
        >
          {{ item.tagName }}
        </a-select-option>
      </a-select>
      <a-icon
        v-if="$p.action('IMAGE_LABELS', '/hat/file')"
        :class="$style.rightIcon"
        type="plus"
        @click="addCallout"
      />
    </a-form-model-item>
    <a-form-model-item labelAlign="left" prop="probability" label="可信度">
      <div :class="$style.probability">
        <a-slider v-model="form.probability" :class="$style.sliderBox" /><span
          :class="$style.info"
          >{{ form.probability }}</span
        >
      </div>
    </a-form-model-item>
    <div :class="$style.footerWrap">
      <a-button @click="close">取消</a-button>
      <a-button type="primary" @click="getValue">确定</a-button>
    </div>
  </a-form-model>
</template>
<script>
import { createFormModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AddCallout from './add-callout.vue';
import { getTagGroup } from '@/services/smart-hat/tag-group';
@Component
export default class AddMarkerDialog extends Vue {
  @Prop({ type: Function }) close;
  @Prop({ type: Function }) save;
  form = {
    probability: 100,
    groupId: '',
    subTagId: '',
  };
  rules = {
    subTagId: [
      {
        required: true,
        trigger: 'blur',
        validator: (rule, value, cb) => {
          if (!this.form.groupId || !this.form.subTagId) {
            cb(new Error('请选择标签'));
          } else {
            cb();
          }
        },
      },
    ],
  };

  async addCallout() {
    const result = await createFormModal(() => <AddCallout />, {
      width: 540,
      title: '标签管理',
    });
    if (result) {
      this.getData();
    }
  }

  mounted() {
    this.getData();
  }

  get subList() {
    if (this.form.groupId) {
      const item = this.panes.find(v => v.pkId === this.form.groupId);
      return item.tags;
    }
    return [];
  }

  panes = [];
  totalSubTagList = [];
  async getData() {
    const data = await getTagGroup();
    this.panes = data;
    data.forEach(item => {
      this.totalSubTagList.push(...item.tags);
    });
  }

  changeGroup() {
    this.form.subTagId = '';
  }

  async getValue() {
    await this.$refs.form.validate();
    const item = JSON.parse(JSON.stringify(this.form));
    item.probability = Number((this.form.probability / 100).toFixed(2));
    const fItem = this.totalSubTagList.find(v => v.pkId === item.subTagId);
    if (fItem) {
      item.tagColor = fItem.tagColor;
    }
    this.save(item);
    this.close();
  }
}
</script>
<style lang="less" module>
.form {
  .rightIcon {
    margin-left: 10px;
  }
  .probability {
    display: flex;
    align-items: center;
    :global {
      .ant-slider {
        margin: 14px 6px;
      }
    }
    .sliderBox {
      flex: 1;
    }
    .info {
      flex-basis: 30px;
      text-align: right;
    }
  }
  .footerWrap {
    padding-top: 20px;
    text-align: right;
    background: 0 0;
    button + button {
      margin-left: 20px;
    }
  }
}
</style>
